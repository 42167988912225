import React from 'react';
import layoutStyles from '../../../stylesheets/components/layout.module.scss';
import svgStyles from '../../../stylesheets/components/svg.module.scss';
import textStyles from '../../../stylesheets/components/text.module.scss';
import spinnerStyles from '../../../stylesheets/components/spinner.module.scss';
import classNames from 'classnames';

interface SubmitSpinnerProps {
  spinnerText: string;
  spinnerSubText?: string;
}

const SubmitSpinner = ({ spinnerText, spinnerSubText }: SubmitSpinnerProps) => {
  return (
    <div
      data-test-class="loading-spinner"
      className={classNames(
        layoutStyles.flex,
        layoutStyles.flexColumn,
        layoutStyles.alignCenter,
        layoutStyles.mt30,
        layoutStyles.mb30
      )}
    >
      <svg
        className={classNames(
          spinnerStyles.spinner,
          layoutStyles.mb20,
          svgStyles.svgStrokeBlue
        )}
        width="42"
        height="42"
        viewBox="0 0 62 62"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className={spinnerStyles.spinnerPath1}
          d="M31 2V11.75"
          stroke="#1472FF"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className={spinnerStyles.spinnerPath2}
          d="M51.75 10.5618L44.8641 17.4477"
          stroke="#1472FF"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className={spinnerStyles.spinnerPath3}
          d="M60.25 31.25H50.5"
          stroke="#1472FF"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className={spinnerStyles.spinnerPath4}
          d="M51.6359 51.9383L44.75 45.0524"
          stroke="#1472FF"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className={spinnerStyles.spinnerPath5}
          d="M31 60.5V50.75"
          stroke="#1472FF"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className={spinnerStyles.spinnerPath6}
          d="M10.3115 51.9383L17.1975 45.0524"
          stroke="#1472FF"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className={spinnerStyles.spinnerPath7}
          d="M1.75 31.25H11.5"
          stroke="#1472FF"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className={spinnerStyles.spinnerPath8}
          d="M10.3115 10.5618L17.1975 17.4477"
          stroke="#1472FF"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      {spinnerSubText && (
        <span
          className={classNames(
            textStyles.fs15,
            textStyles.colorSecondary,
            layoutStyles.mb6
          )}
        >
          {spinnerSubText}
        </span>
      )}
      <h1>{spinnerText}</h1>
    </div>
  );
};

export default SubmitSpinner;
